
import { Component } from '@angular/core';
import { ReclamosService } from '../../services/reclamos.service';

@Component({
  selector: 'app-reclamo-finalizar-modal',
  templateUrl: './reclamo-finalizar-modal.component.html',
  styleUrls: ['./reclamo-finalizar-modal.component.scss']
  })
export class ReclamoFinalizarModalComponent {
  loading = false;

  constructor(private reclamosService: ReclamosService) { }

  dismiss(confirm = false) {
    if (this.loading) return;

    this.loading = true;

    this.reclamosService.confirmTicketClosure(confirm);
  }
}
