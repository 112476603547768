<button mat-icon-button class="close-modal-button" (click)="close()">
    <mat-icon title="Cerrar">close</mat-icon>
</button>
<div class="feedback">
    <span class="material-icons-round">
        check_circle_outline
    </span>
    <h2>Se generó el reclamo n.º {{ complaintData?.nroReclamo }}</h2>
    <h4>Pronto nos contactaremos con vos para darte una resolución.</h4>
</div>
