<button mat-icon-button class="close-modal-button" (click)="closeModal()">
  <mat-icon title="Cerrar">close</mat-icon>
</button>
<h1>Nuevo reclamo para retención n.° {{ retencion?.nroRetencion }}</h1>
<form [formGroup]="form">
  <div class="row" *ngIf="showWarning">
    <div class="col">
      <app-alert type="warning">
        Estás a punto de enviar un reclamo fuera de término y es posible que no sea considerado.
        <a (click)="showDeadlines()" class="d-inline-block">Ver plazos</a>
      </app-alert>
    </div>
  </div>
  <div class="row">
    <div class="col pb-0">
      <div class="label required">Descripción del reclamo</div>
      <textarea
        class="scrollbar-dark"
        rows="4"
        placeholder="Detalle aquí la razón de su reclamo."
        formControlName="descripcion"
        maxlength="250"
        autofocus
        required>
      </textarea>
      <small
        *ngIf="form?.controls?.descripcion?.touched && form?.controls?.descripcion?.hasError('required')"
        class="text-danger float-left">
        Ingrese una descripción
      </small>
      <small class="float-right">{{ form?.value?.descripcion?.length || 0 }}/250</small>
    </div>
  </div>
  <div class="row mt-20px">
    <div class="col">
      <div class="label">Adjuntar archivo</div>
      <app-select-file formControlName="archivo" [acceptTypes]="validFileFormats"></app-select-file>
      <small
        *ngIf="form?.controls?.archivo?.touched && invalidSizeError"
        class="text-danger float-left">
        El archivo supera el tamaño máximo de 8 MB permitido
      </small>
      <small
        *ngIf="form?.controls?.archivo?.touched && invalidFormatError"
        class="text-danger float-left">
        El archivo no posee un formato válido
      </small>
      <small class="float-left" *ngIf="!(invalidFormatError || invalidSizeError)">
        Sólo archivos de imagen, texto o pdf, hasta 8MB
      </small>
    </div>
  </div>
  <div class="row button-row">
    <div class="col">
      <button type="button" class="btn btn-primary btn-block" (click)="sendComplaint()">
        <ng-container *ngIf="!loading">Enviar reclamo</ng-container>
        <i *ngIf="loading">
          <mat-progress-spinner color="white" mode="indeterminate" diameter="19"> </mat-progress-spinner>
        </i>
      </button>
    </div>
  </div>
</form>
