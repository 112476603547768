<div class="d-flex justify-content-end">
    <mat-icon class="material-icons-outlined pointer-event" (click)="dismiss()">close</mat-icon>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <mat-icon class="material-icons-outlined lock-icon">lock</mat-icon>
    <b class="my-3 title">Cerrar consulta</b>
    <div class="mx-3 text-center">Al cerrar la consulta se dará por finalizado el proceso de ayuda.</div>
    <b class="my-3">¿Desea continuar?</b>
    <button class="btn btn-primary" (click)="dismiss(true)">Finalizar</button>
    <button class="btn mt-2" (click)="dismiss()">Cancelar</button>
  </div>
  