<h1>Plazos para reclamos de retenciones disponibles</h1>
<section>
  <p>A partir del 10/01/2022, los plazos perentorios para reclamos de retenciones disponibles son los siguientes:</p>
  <p>
    <b>Retenciones practicadas entre el día 1 y el 15, ambos inclusive:</b> hasta el tercer día hábil inclusive de finalizada la quincena antes mencionada.
  </p>
  <p>
    <b>Retenciones efectuadas desde el 16 y hasta fin de mes, ambos inclusive:</b> hasta el tercer día hábil inclusive de finalizado el mes anterior.
  </p>
</section>
