import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComplaintData, ReclamosService } from '../../services/reclamos.service';

@Component({
  selector: 'app-reclamo-confirmacion',
  templateUrl: './reclamo-confirmacion.component.html',
  styleUrls: ['./reclamo-confirmacion.component.scss'],
  })
export class ReclamoConfirmacionComponent {
  constructor(private reclamosService: ReclamosService, @Inject(MAT_DIALOG_DATA) public complaintData: ComplaintData) {}

  close() {
    this.reclamosService.closeConfirmation();
  }
}
