import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl
} from '@angular/forms';
import { fileFormatValidator, fileSizeValidator } from 'src/app/_shared/helpers/formsCustomValidators';
import { ComplaintData, ReclamosService } from '../../services/reclamos.service';

@Component({
  selector: 'app-reclamo-formulario',
  templateUrl: './reclamo-formulario.component.html',
  styleUrls: ['./reclamo-formulario.component.scss'],
  })
export class ReclamoFormularioComponent implements OnInit {
  retencion: any;

  form: UntypedFormGroup;

  showWarning = false;

  loading = false;

  invalidFormatError = false;

  invalidSizeError = false;

  validFileFormats = `
    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
    application/pdf,
    image/jpeg,
    image/png,
    application/msword,
    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
    application/vnd.ms-powerpoint,
    application/vnd.openxmlformats-officedocument.presentationml.presentation,
    image/bmp,
    image/gif
  `;

  constructor(
    private fb: UntypedFormBuilder,
    private reclamosService: ReclamosService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.retencion = this.reclamosService.retentionRef;

    if (this.retencion) {
      this.showWarning = this.reclamosService.isAfterTheDeadline(this.retencion.fecha, new Date());
    }

    this.form = this.fb.group({
      descripcion: this.fb.control(null, [Validators.required, Validators.maxLength(250)]),
      archivo: this.fb.control(null),
    });

    const fileCtrl = this.form.get('archivo');

    fileCtrl.valueChanges.subscribe(val => {
      if (!val) return;

      const fileFormatFn = fileFormatValidator(this.validFileFormats);
      const fileSizeFn = fileSizeValidator(8);

      this.invalidFormatError = false;
      this.invalidSizeError = false;

      if (fileFormatFn(fileCtrl as UntypedFormControl)) {
        this.invalidFormatError = true;
        fileCtrl.setValue(null);
        return;
      }

      if (fileSizeFn(fileCtrl as UntypedFormControl)) {
        this.invalidSizeError = true;
        fileCtrl.setValue(null);
      }
    });
  }

  closeModal() {
    this.reclamosService.closeComplaintModal();
  }

  sendComplaint() {
    // trim descripction value before validation
    if (this.form.value.descripcion?.length) {
      this.form.get('descripcion').setValue(this.form.value.descripcion.trim());
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    if (this.loading || this.form.invalid) return;

    this.loading = true;

    const data = {
      nroRetencion: this.retencion.nroRetencion?.trim(),
      detalle: this.form.value.descripcion?.trim(),
      ...(this.form.value.archivo && { adjuntos: [this.form.value.archivo] }),
    };

    this.reclamosService.sendNewComplaint(data).subscribe(
      (result: ComplaintData) => {
        this.loading = false;
        this.reclamosService.showConfirmation(result);
      },
      (err) => {
        if (err.error?.errors[0].msg?.errorCode === '507') {
          this.snackBar.open('Ya existe un reclamo registrado para esta retención', 'Cerrar', {
            duration: 10000,
            verticalPosition: 'top'
          });
        }
        this.loading = false;
      }
    );
  }

  showDeadlines() {
    this.reclamosService.showDeadlines();
  }
}
