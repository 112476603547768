<button mat-icon-button class="close-modal-button" (click)="closeModal()">
    <mat-icon title="Cerrar">close</mat-icon>
</button>
<div class="component-container">
    <h1>Ya tenés un reclamo abierto para la retención n.º {{ retention?.nroRetencion }}</h1>
    <section>
        <div class="row border-0" *ngIf="showAlert">
            <div class="col p-0 pb-4">
                <app-alert type="danger">
                    El reclamo se generó fuera de término y es posible que no sea considerado.
                    <a routerLink="#" (click)="showDeadlines()" class="d-inline-block">Ver plazos</a>
                </app-alert>
            </div>
        </div>
        <div class="row">
            <div class="col label">N.° de reclamo</div>
            <div class="col">{{ complaint?.nroReclamo }}</div>
        </div>
          <div class="row">
            <div class="col label">Fecha de creación</div>
            <div class="col">{{ complaint?.fecha | date: 'dd/MM/yyyy' }}</div>
          </div>
          <div class="row">
            <div class="col label">E-mail</div>
            <div class="col">{{ complaint?.mail }}</div>
          </div>
    </section>
</div>
<button type="button" class="btn btn-secondary btn-block" (click)="closeModal('accept')">ACEPTAR</button>