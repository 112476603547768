// class 'Motivo' for to instantiate and map object from database
export class Motivo {
  id: string;

  titulo: string;

  asociadoModulo: number;

  constructor(obj: any) {
    this.id = obj.id;
    this.titulo = obj.titulo;
    this.asociadoModulo = obj.asociadoModulo;
  }
}
