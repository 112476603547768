import { Component, OnInit } from '@angular/core';
import { ComplaintData, ReclamosService } from '../../services/reclamos.service';

@Component({
  selector: 'app-reclamo-detalle',
  templateUrl: './reclamo-detalle.component.html',
  styleUrls: ['./reclamo-detalle.component.scss'],
  })
export class ReclamoDetalleComponent implements OnInit {
  retention: any;

  complaint: ComplaintData;

  showAlert = false;

  constructor(private reclamosService: ReclamosService) {}

  ngOnInit(): void {
    this.retention = this.reclamosService.retentionRef;
    this.complaint = this.reclamosService.complaintData;

    if (this.retention && this.complaint) {
      this.showAlert = this.reclamosService.isAfterTheDeadline(this.retention.fecha, this.complaint.fecha);
    }
  }

  closeModal(role = 'closed') {
    this.reclamosService.closeComplaintDetailModal(role);
  }

  showDeadlines() {
    this.reclamosService.showDeadlines();
  }
}
