// class 'SubMotivo' for to instantiate and map object from database
export class SubMotivo {
  id: number;

  subMotivoId: string;

  subMotivoTitulo: string;

  rol: string;

  asociadoModulo: number;

  constructor(obj: any) {
    this.id = obj.id;
    this.subMotivoId = obj.subMotivoId;
    this.subMotivoTitulo = obj.subMotivoTitulo;
    this.rol = obj.rol;
    this.asociadoModulo = obj.asociadoModulo;
  }
}
